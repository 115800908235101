@import "./../../assets/css/theme";

.wallet-seedbackup-container {
  .MuiDialogContent-root {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 24px;

    > * + * {
      margin-top: 16px;
    }
  }

  .detail {
    opacity: 0.8;
    margin-bottom: 3rem;
  }

  .action-sheet {
    width: 200px;
  }

  .seed-box {
    width: 400px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 16px;
    padding: 24px 36px;
    @include themify-no-context {
      border: 1px solid $background-gray;
      background-color: $background-gray;
    }
    border-radius: 4px;;

    > * {
      margin: 4px 8px;
      padding: 4px 8px;
      font-weight: bold;
      border-radius: 4px;
    }
  }

  .seed-box-selector {
    border: none !important;
    background: transparent !important;
    margin-top: 0;
    > * {
      background-color: #27c6a8;
      color: white;
      cursor: pointer;
    }
  }

  .seed-box-confirm {
    @include themify-no-context {
      border: 1px solid $background-gray !important;
      background-color: $background-gray !important;
    }
  }

  .divider {
    position: relative;
    width: 100%;
    text-align: center;
    margin: 16px 0;
    @include themify-no-context {
      color: $text-color;
    }
    > * {
      position: relative;
      text-transform: uppercase;
      display: inline-block;
      @include themify-no-context {
        background: $background;
      }
      padding: 0 16px;
      z-index: 1;
    }
    @include themify-no-context {
      &:before {
        content: ' ';
        height: 1px;
        width: 100%;
        opacity: 0.8;
        display: block;
        position: absolute;
        top: 50%;
        z-index: 0;
        background: $text-color;
      }
    }
  }

  .btn-secondary {  
    &.Mui-disabled {
      box-shadow: 0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000022, 0px 1px 5px 0px #00000015 !important;
      opacity: 0.5;
      @include themify-no-context {
        color: $button-text !important;
      }
    }
  }

  @media (min-width: 600px)
  {
  }
}
