@import "./../../assets/css/theme";

.footer-block {
  position: fixed;
  bottom: 0;
  width: 100%;
  @include themify-no-context {
    background: $background;
  }
  z-index: 99999;
}

.search-page {
  .btn-toggle-view {
    display: none;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    @include themify-no-context {
      background: $background;
    }
    border-radius: 20px;
    z-index: 999;
  }

  .btn-footer-action {
    position: fixed;
    bottom: 20px;
    right: 100px;
    z-index: 999991;
  }

  @media screen and (max-width: 599px) {
    .btn-footer-action {
      display: none;
    }

    .btn-toggle-view {
      display: block;

      .MuiSvgIcon-root {
        width: 17px;
        height: 20px;
        position: relative;
        top: 5px;
      }
    }
  }
}

.search-lising-section {
  margin-top: 25px;

  .block-title {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 768px) {
    .block-title {
      font-size: 20px;
    }
  }
}

.search-bar-block {
  padding-top: 5px;
  width: 100%;
  @include themify-no-context {
    background: $background;
  }

  .search-form {
    @include themify-no-context {
      border-bottom: 1px solid $caret;
    }

    .btn-filter {
      width: 175px;
    }
  }

  @media screen and (max-width: 768px) {
    .search-form {
      @include themify-no-context {
        border-bottom: 1px solid $caret;
      }

      .btn-filter {
        width: 46%;
        font-size: 12px !important;
      }
    }
  }
}

.search-bar {
  position: sticky;
  top: 65px;
  z-index: 99998;
  @include themify-no-context {
    background: $background;
  }
  padding-top: 1;

  @media screen and (max-width: 960px) {
    top: 65px;
  }
  @media screen and (max-width: 768px) {
    position: unset;
  }
}

.fetch-loader {
  margin: 32px;
}