@import "../../assets/css/theme.scss";

// header {
//   border-bottom: 2px solid #f4f5f7 !important;
// }
.dashboard-nav-logo {
  width: 260px;
  border-right: none;
}

.header-links {
  .nav-icon {
    min-width: auto;
  }

  .alert-badge {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    right: 13px;
    border: 2px solid #fff;
  }

  .alert-badge.color1 {
    background-color: #6cd230;
  }

  .alert-badge.color2 {
    background-color: #fe4c98;
  }

  .user-avtar-img {
    width: 40px;
    height: 40px;
    border: 2px solid #99aabc;
    border-radius: 50%;
    display: inline-block;
  }

  .text-username {
    position: relative;
    left: 5px;
    top: 0;
    font-size: 14px;
  }
}

.nav-link-bar {
  .nav-link {
    height: 60px;
    border-left: 2px solid transparent;

    .nav-icon {
      color: #889aae;
      min-width: auto;
      margin-right: 15px;
    }

    .nav-text {
      font-size: 14px;
      color: #262632 !important;
      text-decoration: none;
      text-transform: uppercase;
      display: block;
      padding: 20px 0;
    }
  }

  .nav-link.active {
    background-color: #f9fafc;
    border-left: 2px solid #e52800;

    .nav-icon {
      color: #e52800;
    }
  }
}

.clear {
  clear: both;
}

.partner-form,
.property-form,
.location-form {
  .tabs-container {
    .tab-lable-img {
      height: 15px !important;
      width: 25px !important;
    }
    .tab-lable-text {
      position: relative;
      top: -4px;
      @include themify-no-context {
        color: $text-color;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .dashboard-nav-logo {
    border-right: none;
  }

  .overview-widget-block-1 {
    margin: 10px 0;
  }
}
