@import "../../assets/css/theme.scss";

.footer {
    padding-top: 24px;
    @include themify-no-context {
        border-top: 1px solid $caret;
    }

    .widget-title {
        margin-top: 30px;
        @include themify-no-context {
            color: $text-color;
        }
        font-size: 16px;
        font-weight: bold;
    }

    .social-links {
        list-style: none;
        margin: 30px 0 10px;
        padding: 0;

        li {
            cursor: pointer;
            @include themify-no-context {
                color: $text-color;
            }
            margin: 0 5px 0 0;
            display: inline-block;
        }
    }

    .footer-links {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            cursor: pointer;
            @include themify-no-context {
                color: $text-color;
            }
            margin: 0 0 5px;
            font-size: 16px
        }
    }

    .footer-bottom {
        margin-top: 15px;
        @include themify-no-context {
            border-top: 1px solid $caret;
            color: $text-color;
        }
        font-size: 13px;
    }
}