@import "./assets/css/theme.scss";

html,
body {
  @include themify-no-context {
    background-color: $background;
  }
}

.fixed {
  position: sticky;
  top: 0;
  z-index: 99999;
  @include themify-no-context {
    background-color: $background;
  }
}

.app-root {
  min-height: calc(100vh - 70px);

  @include themify-no-context {
    background-color: $background;
    color: $text-color;
  }
}

.homeNoStickedHeader {
  background-color: transparent !important;

  .navbar {
    background-color: transparent !important;
    border-bottom: none !important;
  }
}

.modal-delete, 
.modal-confirm {
  padding: 30px 25px !important;

  .modal-delete-content, 
  .modal-confirm-content {
    margin: 10px 0;
    padding: 0 5px;
  }
  .bttn-group {
    width: 100%;
    margin-top: 5px;
  }
  .bttn {
    width: 50%;
    background-color: rgb(255, 255, 255);
  }
  .bttn-delete {
    color: rgb(255, 0, 0);
  }
  .bttn-confirm {
    &:hover {
      color: #27c6a8;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  @include themify-no-context {
    -webkit-text-fill-color: $text-color;
    -webkit-box-shadow: 0 0 0 30px $dashboard-asset inset !important;
  }
}

.not-found {
  min-height: calc(100vh - 200px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8em;
  opacity: 0.2;

  animation: fadeIn 3s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  80% { opacity: 0; }
  100% { opacity: 0.2; }
}