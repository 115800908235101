@import "../../assets/css/theme.scss";

small button {
    float: right;
  }

  a {
    cursor: pointer;
  }

  .col-1 {
    margin-right: 1rem;
    &.right {
      width: 50%;
    }
  }

  .CodeMirror {
    height: auto;
    padding: 1rem;
    border-radius: 0.25rem;
  }

  ul {
    padding-left: 0px;
  }

  li {
    list-style: none;
  }

  ul.tag-box-pills {
    padding-left: 1rem;

    li {
      display: inline-block;
      margin-right: 1rem;
      margin-top: 1rem;
      color: #262632;
      background-color: gold;
      border-radius: 0.25rem;

      .remove {
        margin-left: 0.5rem;
      }

      .tag-box-pill-text {
        margin-left: 0.5rem;
      }

      button {
        padding: 0.25rem 0.75rem;
      }

      &.system {
        background-color: chocolate;
      }
    }
  }

  ul.autocomplete {
    border-bottom: 1px solid #7e69c6;
    padding: 1rem;

    li {
      cursor: pointer;

      &.considering {
        background-color: #7e69c6;
      }

      .option-text {
        padding-left: 0.5rem;
      }
    }
  }

  .tag-box {
    border-top: 1px solid #7e69c6;
    border-left: 1px solid #7e69c6;
    border-right: 1px solid #7e69c6;
    border-radius: 0.25rem 0.25rem 0 0;

    input {
      padding: 1rem;
      border-bottom: 1px solid #7e69c6;
      border-top: none;
      border-left: none;
      border-right: none;
      outline: none;
      background-color: transparent;
      width: 95%;

      @include themify-no-context {
        color: $text-color;
      }
    }
  }
