@import "../../../assets/css/theme.scss";

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__close-icon::after {
  @include themify-no-context {
    background-color: $text-secondary;
  }
}

.tab-panel {
  @include themify-no-context {
    color: $text-loss;
  }

  .page-block-title {
    font-size: 18px;
  }

  .page-description {
    font-size: 16px;
    line-height: 1.5em;
  }

  .user-profile-form {
    margin-top: 20px;

    .form-label {
      @include themify-no-context {
        color: $dashboard-payment-widget-title;
      }
      font-size: 15px;
      font-weight: 300;
      margin: 0 0 10px 0;
      display: block;
    }

    .MuiOutlinedInput-input {
      padding: 14px 15px;
      font-size: 15px;
    }

    .btn {
      padding: 10px 20px !important;
      text-transform: uppercase !important;
      margin: 12px 20px 0 0;

      span.btn-label {
        padding-left: 10px;
      }
    }

    .form-action {
      margin-top: 50px;
    }
  }

  .custom-upload-button {
    position: relative;

    .btn-upload {
      margin-top: 30px;
    }

    .custom-file-input {
      height: 45px;
      position: absolute;
      left: 0;
      top: 27px;
      width: 150px;
      z-index: 111;
      opacity: 0;
      cursor: pointer;
    }
  }
}

.general-information-tab {
  .user-picture-block {
    @include themify-no-context {
      border: 2px solid $dashboard-border;
    }
    padding: 25px 20px;
    font-size: 16px;
    border-radius: 5px;

    .user-avtar {
      width: 80px;
      cursor: pointer;
      height: 80px;
      border-radius: 50%;
      @include themify-no-context {
        background: $background-gray;
      }
      display: inline-block;
      position: relative;

      .profile-icon {
        width: 80px;
        height: 80px;
      }

      .btn-upload {
        @include themify-no-context {
          background: $progress-info-background;
          color: $background;
        }
        width: 35px;
        height: 35px;
        min-width: auto;
        border-radius: 50%;
        right: -15px;
        position: absolute;
        bottom: 0;
      }
    }

    .user-name {
      display: inline-block;
      top: -35px;
      position: relative;
      left: 30px;
      font-size: 18px;
    }
  }
}

.verification-tab {
  .hr-line {
    @include themify-no-context {
      border: 1px solid $background-gray;
    }
    margin: 20px 0;
  }

  .alert-success-box {
    @include themify-no-context {
      border: 3px solid $dashboard-button-buy;
    }
    border-radius: 10px;
    width: fit-content;
    padding: 20px 20px;
    text-transform: uppercase;

    p {
      margin: 0;
    }
  }

  .user-profile-form {
    .custom-upload-button {
      .kyc-document-name {
        font-size: small;
        color: gray;
      }

      .kyc-document-error {
        font-size: 12px;
        @include themify-no-context {
          color: $dashboard-button-buy;
        }
        width: max-content;
      }
    }
  }
}

.payment-source-tab {
  .user-profile-form {
    width: 100%;
    max-width: 700px;
    margin-top: 30px;

    .custom-upload-button {
      .iban-document-name {
        font-size: small;
        @include themify-no-context {
          color: $filter-border;
        }
      }

      .iban-document-error {
        font-size: 12px;
        @include themify-no-context {
          color: $dashboard-button-buy;
        }
        width: max-content;
      }
    }
  }
  .btn-upload {
    margin-top: 20px !important;
  }
}

.security-tab {
  .txt-title {
    @include themify-no-context {
      color: $dashboard-widget-title;
    }
    margin: 0;
    font-size: 15px;
  }

  .user-profile-form {
    width: 100%;
    max-width: 700px;
  }

  .mt-3 {
    margin-top: 3em;
  }

  .hr-line {
    @include themify-no-context {
      border: 1px solid $background-gray;
    }
    margin: 5px 0 25px 0;
  }
}

.settings-tab {
  .list-item {
    @include themify-no-context {
      border-top: 1px solid $dashboard-border;
    }
    padding: 10px 0;

    .txt-type {
      font-size: 16px;
      margin: 5px 0 0;
      font-weight: 600;
    }

    .txt-info {
      @include themify-no-context {
        color: $dashboard-asset;
      }
      font-size: 16px;
      margin: 5px 0 0;
    }

    .item-type {
      display: inline-block;
      width: calc(100% - 60px);
    }

    .check-type {
      display: inline-block;
      width: 60px;
    }

    .select-item-type {
      display: inline-block;
      width: calc(100% - 170px);
    }

    .select-type {
      display: inline-block;
      position: relative;
      width: 170px;
      top: -30px;
    }
  }
}
