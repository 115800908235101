.add-location-page {
  .add-location-form {
    .location-description-lable {
      font-weight: 500;
      font-size: 16px;
    }
  }
}


.manage-location-page {
  .table {
    .MuiButton-root {
      border-color:rgba(0, 0, 0, 0.42);
    }

    tr {
      td:last-child {
        width: 140px;
        vertical-align: middle;
      }
    }
  }
}
