@import './../../assets/css/theme';

.registration-box{
    width: 100%;
    max-width: 500px;
    margin: 5em auto 20px;
    text-align: center;
    color: $primary-color;
    
    .btn-social-login{
        width: 100%;
        max-width: 300px;
        margin-top: 20px;
        margin-bottom: 30px;
    }

    .form{
        padding: 10px 15px;
        .btn-submit{
            margin-top: 30px;
            margin-bottom: 20px;
            width: 100%;
            max-width: 300px;
        }
    }
}

.modal-success{
    color: $primary-color !important;
    padding: 30px 25px !important;

    .btn-login{
        margin-top: 30px;
    }
}