

.react-tel-input {
  .form-control {
    font-size: 15px;
    width: 100%;
    padding: 14px 15px 13px 58px;
    &:focus {
    background-color: #fff;
    border-color: #000 !important;
    outline: 0;
    box-shadow: 0 0 0 1px rgb(0,0,0) !important;
    }
    &.open {
    background-color: #fff;
    border-color: #000 !important;
    outline: 0;
    box-shadow: 0 0 0 1px rgb(0,0,0) !important;
    }
  }
}

.invalid {
  .react-tel-input {
    .form-control {
      border-color: red;
    }
  }
}