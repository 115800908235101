@import "../../assets/css/theme.scss";

body {
  font-family: "Open Sans", Arial, sans-serif !important;
}

.dashboard-content header {
  height: 78px;
}

.navbarlink-drawer .MuiPaper-root {
  top: 78px;
}
.nav-logo {
  cursor: pointer;
  flex-grow: 1;
  padding: 3px 25px;

  img {
      width: 200px;
  }
}
.dashboard-nav-logo {
  width: 260px;
  @include themify-no-context {
    border-right: none;
  }
}

.header-links {
  .nav-icon {
    min-width: auto;
  }

  .alert-badge {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    right: 13px;
    @include themify-no-context {
      border: 2px solid $background;
    }
  }

  .alert-badge.color1 {
    @include themify-no-context {
      background-color: $progress-success-background;
    }
  }

  .alert-badge.color2 {
    @include themify-no-context {
      background-color: $progress-danger-background;
    }
  }

  .user-avtar-img {
    width: 40px;
    height: 40px;
    @include themify-no-context {
      border: 2px solid $dashboard-date;
    }
    border-radius: 50%;
    display: inline-block;

    svg {
      width: 40px;
      height: 40px;
    }
  }

  .text-username {
    position: relative;
    left: 5px;
    top: 0;
    font-size: 14px;
  }
}

.nav-link-bar {
  .nav-link {
    height: 60px;
    border-left: 2px solid transparent;

    .nav-icon {
      @include themify-no-context {
        color: $dashboard-deposit;
      }
      min-width: auto;
      margin-right: 15px;
    }

    .nav-text {
      font-size: 14px;
      @include themify-no-context {
        color: $text-color !important;
      }
      text-decoration: none;
      text-transform: uppercase;
      display: block;
      padding: 20px 0;
    }
  }

  .nav-link.active {
    @include themify-no-context {
      background-color: $dashboard-background;
      border-left: 2px solid $dashboard-button-buy;

      .nav-icon {
        color: $dashboard-button-buy;
      }
    }
  }
}

.property-data {
  cursor: pointer;
}

.overview-widget-block-1 {
  @include themify-no-context {
    background: $background;
  }
  margin: 0 15px 10px 0;
  border-radius: 12px;

  p {
    margin: 5px 0;
  }

  .value-block {
    padding: 15px 20px 5px;

    .widget-block-2 {
      span {
        width: 50%;
        display: inline-block;
      }
    }

    .txt-value {
      font-size: 20px;
    }

    .txt-title {
      margin: 5px 0;
      font-size: 14px;
      @include themify-no-context {
        color: $dashboard-date;
      }
    }

    .txt-percentage {
      font-size: 20px;
      float: right;
    }

    .percentage-profit {
      @include themify-no-context {
        color: $text-profit;
      }
      &:before {
        content: "+ ";
      }

      &:after {
        content: " %";
      }
    }

    .percentage-loss {
      @include themify-no-context {
        color: $dashboard-button-buy;
      }
      &:after {
        content: " %";
      }
    }

    .txt-portfolio-value {
      font-size: 15px;
      float: right;
    }

    .portfolio-profit {
      @include themify-no-context {
        color: $text-profit;
      }
      &:before {
        content: "+ ";
      }

      &:after {
        content: " $";
      }
    }

    .portfolio-loss {
      @include themify-no-context {
        color: $dashboard-button-buy;
      }
      &:after {
        content: " $";
      }
    }

    .profit-loss-percentage {
      margin-left: 30px;
    }
  }
}

.overview-widget-block-2 {
  @include themify-no-context {
    background: $background;
  }
  margin: 50px 15px 0px 0;
  border-radius: 12px;

  p {
    margin: 5px 0;
  }

  .left-block {
    width: 50%;
    display: inline-block;

    .content-box {
      margin-top: 20px;
      padding-left: 30px;
      position: relative;

      .status-badge {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        top: 8px;
        left: 10px;
      }

      .status-badge.color1 {
        @include themify-no-context {
          background-color: $text-secondary;
        }
      }

      .status-badge.color2 {
        @include themify-no-context {
          background-color: $text-profit;
        }
      }

      .txt-value {
        font-size: 20px;
        @include themify-no-context {
          color: $text-color;
        }
      }

      .txt-title {
        font-size: 14px;
        @include themify-no-context {
          color: $dashboard-date;
        }
      }
    }
  }

  .right-block {
    width: 50%;
    float: right;

    .CircularProgressbar-path {
      stroke: #ffffff00;
    }

    .CircularProgressbar-trail {
      stroke: #ffffff00;
    }

    .progress-bar-total-funds {
      width: 150px;
      margin: 15px auto;
      margin-left: 0px;

      .txt-value {
        margin: 0;
        @include themify-no-context {
          color: $text-loss;
        }
        font-size: 22px;
        font-weight: bold;
      }

      .txt-title {
        margin: 0;
        @include themify-no-context {
          color: $dashboard-block-title;
        }
        font-size: 14px;
      }
    }
  }
}

.dashboard-investments-widget {
  position: relative;
  min-height: 100px;
  .table {
    width: 100%;
    @include themify-no-context {
      background: $background;
    }
    padding: 10px 15px;
    border-radius: 12px;

    .center-text {
      text-align: center;
    }

    .custom-td {
      border-bottom: none;
      @include themify-no-context {
        color: $dashboard-block-title;
      }
      font-size: 14px;
    }

    td {
      @include themify-no-context {
        border-bottom: 2px solid $dashboard-border;
      }
      padding: 10px 5px;
    }

    .property-img {
      width: 80px;
      height: 60px;
      max-height: 60px;
      border-radius: 5px;
      object-fit: cover;
    }

    .txt-property-name {
      font-size: 16px;
      margin: 5px 0;
    }

    .txt-investment-title {
      font-size: 14px;
      @include themify-no-context {
        color: $dashboard-block-title;
      }
      margin: 5px 0;
    }

    .txt-funding-progress {
      font-size: 14px;
      @include themify-no-context {
        color: $dashboard-block-title;
      }
      margin: 5px 0;
    }

    .txt-profit-loss {
      font-size: 18px;
      margin: 5px 0;

      .profit {
        @include themify-no-context {
          color: $text-profit;
        }
        &:before {
          content: "+ ";
        }
      }
      .loss {
        &:before {
          content: "- ";
        }
      }
    }
  }
}

.property-map-widget {
  .cluster-marker {
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    text-align: center;
    font-size: 16px;
    @include themify-no-context {
      color: $background;
      background-color: $dashboard-button-buy;
    }
  }
  .marker {
    position: absolute;
    transform: translate(-50%, -100%);
    @include themify-no-context {
      svg {
        width: 25px !important;
        height: 25px !important;
        fill: $dashboard-button-buy;
      }
    }
  }

  .hint--info .hint__content {
    @include themify-no-context {
      background-color: $hint-color;
    }
  }
  .hint--top .hint__content {
    margin-left: -18px;
    bottom: 100%;
    left: calc(50% - 6px);
  }
  .hint .hint__content,
  [data-hint] .hint__content {
    position: absolute;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: hidden;
    opacity: 0;
    z-index: 1000000;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  .hint__content {
    @include themify-no-context {
      background: $dashboard-payment-widget-title;
      color: $background;
    }
    border-radius: 5px;
    padding: 8px 10px;
    font-size: 13px;
    line-height: 14px;
  }
  .hint--top:hover .hint__content,
  .hint--top:focus .hint__content {
    -webkit-transform: translateY(-8px);
    -ms-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  .hint:hover .hint__content,
  [data-hint]:hover .hint__content {
    -webkit-transition-delay: 100ms;
    transition-delay: 100ms;
  }
  .hint:hover .hint__content,
  .hint:focus .hint__content,
  [data-hint]:hover .hint__content,
  [data-hint]:focus .hint__content {
    visibility: visible;
    opacity: 1;
  }
  .hint--top .hint__content:after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 0;
    height: 12px;
    width: 100%;
    opacity: 0;
    @include themify-no-context {
      background-color: $dashboard-button-buy;
    }
  }
  .hint:hover:before,
  .hint:hover:after,
  .hint:focus:before,
  .hint:focus:after,
  [data-hint]:hover:before,
  [data-hint]:hover:after,
  [data-hint]:focus:before,
  [data-hint]:focus:after {
    visibility: visible;
    opacity: 1;
  }

  .hint--top:before,
  .hint--top:after {
    left: calc(50% + 6px);
  }
  .hint--top:before,
  .hint--top:after {
    bottom: 100%;
  }
  .hint--top:before {
    margin-bottom: -12px;
  }
  .hint--top:before {
    @include themify-no-context {
      border-top-color: $dashboard-payment-widget-title;
    }
  }
  .hint:before,
  [data-hint]:before {
    content: "";
    position: absolute;
    background: 0 0;
    border: 6px solid transparent;
    z-index: 1000001;
  }
  .hint:before,
  .hint:after,
  [data-hint]:before,
  [data-hint]:after {
    position: absolute;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: hidden;
    opacity: 0;
    z-index: 1000000;
    pointer-events: none;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  .hint--info.hint--top:before {
    @include themify-no-context {
      border-top-color: $hint-color;
    }
  }
  .hint--top:hover:after,
  .hint--top:hover:before,
  .hint--top:focus:after,
  .hint--top:focus:before {
    -webkit-transform: translateY(-8px);
    -ms-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  .hint:hover:before,
  .hint:hover:after,
  [data-hint]:hover:before,
  [data-hint]:hover:after {
    -webkit-transition-delay: 100ms;
    transition-delay: 100ms;
  }
}

.investments-widget {
  .widget-title {
    float: right;
    margin-top: 2px;
  }
}

.clear {
  clear: both;
}

@media screen and (max-width: 768px) {
  .dashboard-nav-logo {
    border-right: none;
  }

  .overview-widget-block-1 {
    margin: 10px 0;
  }
}

.txt-title-secondary {
  font-size: 19px !important;
  font-weight: bold !important;
}
