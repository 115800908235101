@import "../../assets/css/theme.scss";

.add-property-page {
  .MuiCard-root {
    overflow: visible;
  }
  .add-property-form {
    .block-1 {
      .autocomplete-form-label {
        color: #3f424b;
        font-size: 15px;
        font-weight: 300;
        margin: 20px 0px 10px 0;
        display: block;
      }

      .autocomplete-input {
        padding: 0px;
        font-size: 15px;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border-bottom: 1px solid rgba(0, 0, 0, 0.42);
        outline: transparent;
        width: "100%";

        &::placeholder {
          text-transform: lowercase;
          font-size: small;
        }
      }

      .MuiOutlinedInput-input {
        padding: 14px 15px;
        font-size: 15px;
      }

      .editor-error {
        color: #f44336;
        font-size: 12px;
        text-align: left;
      }

      .form-label {
        color: #3f424b;
        font-size: 15px;
        font-weight: 300;
        margin: 0 0 10px 0;
        display: block;
      }

      .MuiOutlinedInput-input {
        padding: 14px 15px;
        font-size: 15px;
      }

      .default-card {
        overflow: auto;
        // overflow-x: scroll;
        .action-button {
          max-width: 30px;
          max-height: 30px;
          min-width: 30px;
          min-height: 30px;
        }
      }

      .forecasted-distribution-lable {
        font-weight: 500;
        font-size: 16px;
      }

      .legal-warnings-lable {
        font-weight: 500;
        font-size: 16px;
      }

      .location-image {
        cursor: pointer;
        height: 150px;
        width: 100%;
      }

      .location-image-button {
        margin-left: 15px;
      }
    }

    .hide {
      display: none;
    }

    .block-3 {
      .upload-picture-file {
        display: none;
      }

      .floor-plan-image-file {
        display: none;
      }

      .floor-plan-image {
        cursor: pointer;
        height: 150px;
        width: 150px;
        border-radius: 6px;
      }

      .floor_plan_box {
        position: relative;

        .action-button {
          position: absolute;
          right: 8px;
          top: 8px;
          width: 24px;
          min-width: 24px;
          height: 24px;
          border-radius: 24px;
        }
      }

      .property-picture-item-container {
        padding: 10px;
        margin-top: 5px;
        .picture {
          cursor: pointer;
          height: 150px;
          width: auto;
          border-radius: 6px;
        }

        .action-button {
          max-width: 30px;
          max-height: 30px;
          min-width: 30px;
          min-height: 30px;
        }
      }
    }

    .block-4 {
      .custom-date-picker {
        .calendar {
          z-index: 99999;
        }
      }
    }

    .block-5 {
      .upload-doc-file {
        display: none;
      }

      .property-file-item-container {
        padding: 10px;
        margin-top: 5px;
        .file-icon-container {
          text-align: center;
          .file-icon {
            height: 50px;
          }
          .file-name {
            font-size: 10px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: grey;
          }
        }

        .action-button {
          max-width: 30px;
          max-height: 30px;
          min-width: 30px;
          min-height: 30px;
        }
      }
    }

    .action-buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      > * + * {
        margin-left: 8px;
      }
    }
  }


  .MuiStepIcon-text {
    @include themify-no-context {
      fill: $background;
    }
  }
  .MuiStepLabel-label.MuiStepLabel-completed,
  .MuiStepLabel-label.MuiStepLabel-active {
    @include themify-no-context {
      color: $text-color;
    }
  }

  .MuiStepIcon-root.MuiStepIcon-active {
    @include themify-no-context {
      .MuiStepIcon-text { 
        fill: #fff;
      }
    }
  }
}

.manage-property-page {
  .table {
    .MuiButton-root {
      border-color:rgba(0, 0, 0, 0.42);
    }
  }
}

@media screen and (max-width: 991px) {
  .MuiStepper-root {
    display: block !important;

    .MuiStepConnector-root {
      display: none;
    }
  }

  .MuiStep-horizontal {
    padding: 3px 0;
  }
}
@media screen and (max-width: 767px) {
  .react-date-picker__calendar {
    width: 240px !important;
  }
  .custom-date-picker .react-date-picker button {
    font-size: 13px;
    padding: 5px;
  }

  .react-calendar__navigation button {
    min-width: 36px !important;
  }

  .custom-date-picker {
    .react-date-picker {
      .react-calendar__year-view {
        .react-calendar__tile {
          padding: 15px 0px;
        }
      }
    }
  }
}
