@import "./../../assets/css/theme";

.property-card-box {
  width: 100%;
  margin: 10px auto;
  position: relative;
  box-shadow: none !important;
  @include themify-no-context {
    background-color: $background;
    color: $text-color;
  }

  .property-images {
    img {
      width: 100%;
      max-height: 200px;
      border-radius: 0 0 5px 5px;
    }
  }

  .property-content {
    padding: 0 !important;

    .property-details {
      text-align: center;
      @include themify-no-context {
        background: $background;
      }
      padding: 5px 20px;

      p {
        margin: 0;
      }

      .property-name {
        font-size: 22px;
        @include themify-no-context {
          color: $button-text;
        }
        margin-bottom: 5px;
      }

      .pledged-info {
        font-size: 14px;
        @include themify-no-context {
          color: $button-text;
        }
        padding: 10px 15px;
      }
    }

    .property-values {
      width: 100%;
      padding: 10px 15px;

      .txt-title {
        text-align: left;
        @include themify-no-context {
          color: $react-table-header-text;
        }
        font-size: 13px;
      }

      .txt-value {
        text-align: right;
        @include themify-no-context {
          color: $button-text;
        }
        font-size: 16px;
      }
    }
  }
}

.property-location {
  font-size: 14px;
  @include themify-no-context {
    color: $text-secondary;
  }
  margin: 0 auto;
  padding: 5px 0;

  .MuiSvgIcon-root {
    margin-top: -2px;
    width: 22px;
    height: 22px;
  }

  span {
    position: relative;
    top: -4px;
    @include themify-no-context {
      color: $text-color;
    }
  }

  .badge-new {
    background-color: $secondary-color;
    @include themify-no-context {
      color: $background;
    }
    padding: 3px 10px;
    font-weight: 600;
    border-radius: 8px;
    float: right;
    top: 0;
  }
}

.property-financial-block {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  div {
    @include themify-no-context {
      border-right: 1px solid $filter-border;
    }    
    width: calc(100% / 3);
    max-width: calc(100% / 3);

    &:last-child {
      border-right: none;
    }
  }

  .txt-title {
    font-size: 12px;
    @include themify-no-context {
      color: $dashboard-widget-title;
    }
    margin: 5px 0 0;
    font-weight: 300;
  }

  .txt-value {
    font-size: 16px;
    @include themify-no-context {
      color: $text-color;
    }
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 4px;
  }
}

.property-card-box.grid-view {
  .property-images {
    position: relative;
  }

  .property-image {
    width: 100%;
    height: 200px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 12px;
  }

  .property-details {
    width: 100%;
    max-width: 100%;
    z-index: 1;
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    padding: 5px 0;
    text-align: left;
    border-radius: 0 0 12px 12px;

    .property-name {
      color: #fff;
      padding-left: 15px;
      font-size: 14px;
      margin: 0;
    }
  }
}

.list-view.secondary-type {
  .property-details {
    margin-top: 35px;
  }
}

@media screen and (max-width: 768px) {
  .list-view.secondary-type {
    .property-details {
      margin-top: 10px;
    }
  }
}

.list-view {
  max-width: 800px;
  width: 100%;
  @include themify-no-context {
    border: 1px solid $background-gray;
  }
  margin: 20px 0;

  .property-images {
    width: 50%;
    float: left;

    img {
      height: 240px;
      max-height: 240px;
    }
  }

  .property-content {
    width: 50%;
    float: left;

    .property-details {
      .property-location {
        padding: 5px 15px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .property-images {
      width: auto;
      float: none;
    }

    .property-content {
      width: auto;
      float: none;
    }
  }
}
