@import "./../../assets/css/theme";

.reset-password-box {
  width: 100%;
  max-width: 500px;
  margin: 8em auto 10em;
  text-align: center;

  .reset-password-form {
    margin-top: 1em;
    padding: 10px 15px;

    .btn-submit {
      margin-top: 30px;
      margin-bottom: 20px;
      width: 100%;
      max-width: 300px;
    }
  }

  .MuiSnackbar-root {
    z-index: 2000;
  }
}
