@import "./../../assets/css/theme";
@import "styles";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,700&display=swap");

body {
  font-family: "Open Sans", Arial, sans-serif !important;
  font-size: 14px;
  margin: 0;
  color: #262632;
}

.property-details-page {

  .btn-footer-action {
    position: fixed;
    bottom: 20px;
    right: 100px;
    background: #FFF;
    z-index: 999991;
  }

  @media screen and (max-width: 768px) {
    .btn-footer-action {
      display: none;
    }

    .btn-toggle-view {
      display: block;

      .MuiSvgIcon-root {
        width: 17px;
        height: 20px;
        position: relative;
        top: 5px;
      }
    }
  }

}
