@import "../../assets/css/theme.scss";

.hero-banner {
  height: 100vh;
  margin-top: -80px;
  background: url("./../../assets/images/main-bg-image.jpg") ;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  .banner-title {
    font-size: 47px;
  }

  .search-form {
    width: 100%;
    max-width: 505px;
    margin: 0 auto;
    @include themify-no-context {
      background: $background;
      color: $text-color;
    }
    border-radius: 12px;
    position: absolute;
    left: 100px;
    top: 50%;
    transform: translateY(-50%);
    padding: 30px 25px;

    .search-title {
      font-size: 20px;
      font-weight: 600;
      margin: 0 0 20px;
    }

    .btn-filter {
      margin: 0 !important;
      border: none !important;
      font-size: 14px !important;
      height: 30px !important;
      justify-content: left !important;
      background-color: transparent;
    }
  }

  .form-control {
    width: 100%;
    margin: 0 10px 10px 0;
    @include themify-no-context {
      border: 1px solid $range-border;
      background: $background;
    }
    border-radius: 5px;
    padding: 3px 0;    
    max-width: 240px;

    @media screen and (min-width: 617px) {
      &.double-width {
        max-width: 492px;      
      }
    }

    .MuiSelect-selectMenu {
      padding: 5px 15px;
    }
  }

  @media screen and (max-width: 768px) {
    padding-top: 50px;

    .search-form {
      position: initial;
      margin: 60px auto 0;
      transform: none;
      width: unset;

      .search-title {
        font-size: 20px;
      }
    }
  }
}

.market-lising-section {
  margin: 40px 0;
  min-height: 200px;

  .property-slider {
    margin: 0;
  }

  .block-title {
    margin-bottom: 20px;
    font-weight: 500;
    text-transform: capitalize;
  }

  .show-all-link {
    font-weight: 600;
    font-size: 16px;
    margin-top: 2em;
  }

  @media screen and (max-width: 768px) {
    .block-title {
      font-size: 20px;
    }

    .property-slider {
      margin: 0 20px;
    }
  }
}
