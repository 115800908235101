@import "../../assets/css/theme.scss";

.manage-user-page {
  .table {
    .center {
      text-align: center;
    }
  
    .MuiButton-root {
      border-color:rgba(0, 0, 0, 0.42);
    }
  
    tr {
      td:last-child {
        width: 100px;
        vertical-align: middle;

        > * {
          width: 100%;
          display: block;
          > button {
            width: 100%;
          }
        }
      }
    }
  }
}

.edit-user-page {
  .default-form {
    .form-label {
      @include themify-no-context {
        color: $dashboard-widget-title;
      }
      font-size: 15px;
      font-weight: 300;
      margin: 0 0 10px 0;
      display: block;
    }

    .MuiOutlinedInput-input {
      padding: 14px 15px;
      font-size: 15px;
    }
  }

  .new-form {
    .MuiFormControl-root {
      max-width: 360px;
      width: 100%;
    }

    .password-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-content: center;
      width: 100%;
      max-width: 360px;
    }

    .MuiButton-text {
      background-color: transparent;
    }
  }

  h3 {
    padding-left: 16px;
  }

  .MuiCard-root {
    margin-bottom: 48px;
  }

  .MuiGrid-root.label {
    font-weight: bold;
    align-self: center;
  }

  .danger {
    .MuiGrid-root.label {
      color: red;
    }

    &.MuiButton-root {
      color: white;
      background-color: red;
    }
  }

  h3.danger {
    color: red;
  }
}