@import "../../assets/css/theme.scss";

.dashboard-content {
  @include themify-no-context {
    background: $dashboard-background;
  }

  .main-content {
    width: calc(100% - 263px - 50px);
    margin-left: 263px;
    margin-top: 70px;
    padding: 20px 25px;

    @media screen and (max-width: 959px) {
      width: calc(100% - 50px);
      margin-left: 0;
    }

    .block-title {
      @include themify-no-context {
        color: $dashboard-block-title;
      }
      font-size: 16px;
      font-weight: 500;
      margin: 15px 0;
    }

    .widget-title {
      @include themify-no-context {
        color: $dashboard-widget-title;
      }
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      text-align: right;
      margin-right: 30px;
    }

    .btn-toggle-view {
      display: none;
      position: fixed;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      @include themify-no-context {
        background: $background;
      }
      border-radius: 20px;
      z-index: 999;
    }
  }

  .investment-wallet-page {
    .rt-td {
      cursor: pointer;
    }

    .widget-block-title {
      font-size: 16px;
      @include themify-no-context {
        color: $dashboard-widget-block-title;
      }
    }

    .btn-buy {
      @include themify-no-context {
        color: $dashboard-button-buy;
      }
      padding: 0;
    }

    .funding-progress {
      width: 100px;
    }

    .txt-view-full-report {
      margin: 0;
      font-size: 16px;
      padding: 0 15px 10px;

      a {
        @include themify-no-context {
          color: $text-color !important;
        }
      }

      .MuiSvgIcon-root {
        position: relative;
        top: 0;
        float: right;
      }
    }
  }

  .transactions-page {
    .transactions-box {
      @include themify-no-context {
        background: $background;
      }
      position: relative;
      max-height: calc(100vh - 130px);
      overflow-y: scroll;

      .box-shadow {
        height: 70px;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        -webkit-box-shadow: inset 0px -70px 32px -25px rgba(255, 255, 255, 0.8);
        -moz-box-shadow: inset 0px -70px 32px -25px rgba(255, 255, 255, 0.8);
        box-shadow: inset 0px -70px 32px -25px rgba(255, 255, 255, 0.8);
      }

      .table-header {
        .right-block {
          font-size: 13px;
          position: relative;
          top: 8px;

          .txt-title {
            @include themify-no-context {
              color: $dashboard-table-header;
            }
            margin: 0 15px;
          }

          .txt-value {
            @include themify-no-context {
              color: $text-color;
            }
            width: 140px;
            display: inline-block;

            svg {
              top: 7px;
            }
          }
        }
      }

      .table {
        td {
          padding: 5px 10px;
        }
        p {
          margin: 5px 0;
        }

        .symbol-icon {
          width: 50px;
          height: 50px;
          @include themify-no-context {
            background: $dashboard-symbol-background;
            color: $dashboard-symbol-color;
          }
          border-radius: 50%;
          margin: 3px 0;
          line-height: 65px;
          text-align: center;
        }

        .txt-symbol {
          font-size: 16px;
          @include themify-no-context {
            color: $text-color;
          }
        }

        .txt-date,
        .txt-wallet-title,
        .txt-asset-title {
          font-size: 14px;
          @include themify-no-context {
            color: $dashboard-asset;
          }
        }

        .txt-shares,
        .txt-wallet-address {
          margin: 10px 0 0;
        }

        .txt-shares.credit {
          @include themify-no-context {
            color: $dashboard-credit;
          }

          &:before {
            content: "+ ";
          }
        }

        .txt-shares.debit {
          @include themify-no-context {
            color: $text-color;
          }

          &:before {
            content: "- ";
          }
        }

        .btn-status {
          @include themify-no-context {
            background: $dashboard-background;
          }
          padding: 10px 15px;
          border-radius: 20px;
          margin: 10px 0 0;
          font-size: 15px;
          width: 110px;
          text-transform: capitalize;

          .transaction-status-badge {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 10px;
          }

          .transaction-status-badge.successfull {
            @include themify-no-context {
              background: $dashboard-transaction-success;
            }
          }

          .transaction-status-badge.pending {
            @include themify-no-context {
              background: $dashboard-transaction-pending;
            }
          }

          .transaction-status-badge.cancelled {
            @include themify-no-context {
              background: $dashboard-transaction-cancel;
            }
          }
        }

        .btn-more {
          margin: 10px 0;
        }
      }
    }
  }

  .payments-page {
    p {
      margin: 0;
    }

    .widget-block-title {
      padding: 15px 20px;
      font-size: 16px;
      @include themify-no-context {
        color: $dashboard-payment-widget-title;
        border-bottom: 2px solid $dashboard-border;
      }

      .txt-icon {
        width: 60px;
        height: 35px;
        @include themify-no-context {
          background: $dashboard-payment-icon-background;
        }
        border-radius: 5px;
        margin: 0 15px;
        float: left;
      }

      .txt-title {
        position: relative;
        top: 5px;
        font-weight: 500;
      }

      .txt-value {
        float: right;
        position: relative;
        top: 5px;
      }
    }

    .widget-block-content {
      padding: 25px 0 10px;

      .account-card {
        @include themify-no-context {
          background: $dashboard-payment-account-card-background;
          color: $background;
        }
        border-radius: 7px;
        box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.2);
        width: 100%;
        max-width: 330px;
        margin: 10px auto;

        p {
          margin: 0;
        }

        .funds-box {
          width: auto;
          display: inline-block;
          padding: 10px 15px 0;
        }

        .account-number-box {
          margin-top: 30px;
          padding: 0 15px;
        }

        .name-box {
          padding: 10px 15px;
          width: auto;
          display: inline-block;
          text-transform: capitalize;
        }

        .txt-amount-value {
          font-size: 24px;
          font-weight: 500;
        }

        .txt-upper {
          text-transform: uppercase;
        }

        .txt-value {
          font-size: 16px;
          font-weight: 500;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .txt-title {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }

    .widget-block-action {
      .btn-deposit {
        float: right;
        @include themify-no-context {
          color: $dashboard-deposit;
        }
        margin: 0 20px 20px;
      }
    }

    .accounts-spendings-block {
      margin-top: 20px;
      padding: 15px 30px;

      .title-block {
        padding: 15px 0;
        font-size: 16px;
        @include themify-no-context {
          color: $text-color;
          border-bottom: 2px solid $dashboard-border;
        }

        .txt-title {
          font-weight: 400;
        }

        .txt-date {
          text-transform: uppercase;
          float: right;
          font-size: 13px;
          font-weight: 500;

          span {
            @include themify-no-context {
              color: $dashboard-deposit;
            }
            margin-right: 10px;
          }
        }
      }

      .content-block {
        padding: 15px 0;

        .list-group-item {
          p {
            margin: 15px 0;
          }

          .txt-title {
            @include themify-no-context {
              color: $dashboard-deposit;
            }
          }

          .txt-value {
            @include themify-no-context {
              color: $text-color;
            }
            float: right;
          }
        }
      }

      .content-footer {
        display: table;
        width: 100%;

        div {
          display: table-cell;
          text-align: right;
          @include themify-no-context {
            color: $dashboard-payment-footer;
          }
          font-weight: 300;
          font-size: 16px;
        }
      }
    }

    .payment-lists-block {
      .table-header {
        .right-block {
          font-size: 13px;
          position: relative;
          top: 8px;

          .txt-title {
            @include themify-no-context {
              color: $dashboard-table-header;
            }
            margin: 0 15px;
          }
        }
      }

      .table {
        td {
          padding: 5px 6px;
          @include themify-no-context {
            border-bottom: 1px solid $dashboard-table-border;
          }
        }

        .payment-icon {
          @include themify-no-context {
            background: $dashboard-symbol-background;
            color: $dashboard-transaction-pending;
          }
          width: 50px;
          height: 50px;
          border-radius: 50%;
          line-height: 60px;
          text-align: center;
        }

        .txt-type {
          margin-top: 3px;
          font-size: 16px;
        }

        .txt-value {
          margin-top: 3px;
          font-size: 15px;
        }

        .txt-date,
        .txt-name {
          @include themify-no-context {
            color: $dashboard-date;
          }
          font-size: 15px;
        }
      }

      .has-no-payment {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .profile-page {
    @include themify-no-context {
      background: $background;
    }
    display: flex;
    flex-grow: 1;
    padding: 15px 20px;
    border-radius: 12px;

    .tab-bar-links {
      @include themify-no-context {
        border-left: 0px solid $dashboard-table-border;
      }
      width: 180px;
      height: calc(100vh - 150px);
      right: 30px;
      position: absolute;
    }

    .tab-panel {
      width: calc(100% - 185px);
      min-height: calc(100vh - 150px);
    }

    .MuiTab-wrapper {
      align-items: flex-start;
      text-transform: capitalize;
      font-size: 15px;
    }

    .spacing {
      margin-top: calc(100vh - 500px) !important;
    }

    .MuiTab-root {
      min-height: auto;
    }

    .MuiTabs-indicator {
      @include themify-no-context {
        background: $button-text !important;
      }
    }
  }

  .orders-page {
    .rt-td {
      cursor: pointer;
    }

    .widget-block-title {
      font-size: 16px;
      @include themify-no-context {
        color: $dashboard-widget-block-title;
      }
    }

    .btn-order-cancel {
      @include themify-no-context {
        color: $dashboard-button-buy;
      }
      padding: 0;
    }
  }

  .btn-footer-action {
    position: fixed;
    bottom: 20px;
    right: 100px;
    z-index: 999991;
  }

  @media screen and (max-width: 960px) {
    .main-content {
      width: calc(100% - 30px);
      margin-left: 0;
      margin-top: 70px;
      padding: 20px 15px;
    }

    .btn-footer-action {
      display: none;
    }

    .btn-toggle-view {
      display: block;

      .MuiSvgIcon-root {
        width: 17px;
        height: 20px;
        position: relative;
        top: 5px;
      }
    }

    .profile-page {
      display: block;
      .MuiTabs-flexContainerVertical {
        flex-direction: row;
      }
      .tab-bar-links {
        border-left: none;
        @include themify-no-context {
          border-bottom: 1px solid $dashboard-table-border;
        }
        width: 100%;
        height: 50px;
        position: relative;
        right: unset;
        .Mui-selected {
          opacity: 1;
          @include themify-no-context {
            border-bottom: 2px solid $button-color;
          }
        }
      }
      .tab-panel {
        width: 100%;
        min-height: unset;
      }
      .spacing {
        margin-top: 0 !important;
      }
      .MuiTabs-indicator {
        display: none;
      }
    }
  }
}

.downloads-table {
  margin-top: 10px;
}