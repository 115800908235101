@import './../../assets/css/theme';

.navbar {
    background-color: $background !important;
    box-shadow: none !important;
    @include themify-no-context {
        border-bottom: 1px solid $caret;
    }

    .nav-logo {
        cursor: pointer;
        flex-grow: 1;
        padding: 3px 25px;

        img {
            width: 200px;
        }
    }
}

.btn-menu-toggle {
    padding: 0 5px 0 10px !important;
}

.header-link-container {
    padding-top: 56px;
    padding-left: 30px;
}

.header-links {
    display: inline-flex;
    right: 30px;
    top: 0px;
    @include themify-no-context {
        box-shadow: 0 0 20px 0px $background;
    }    

    li {
        width: auto;
        min-height: auto;
        padding: 4px 32px 12px;

        a {
            @include themify-no-context {
                color: $button-text !important;
            }
            cursor: pointer;
            text-decoration: none;
        }
    }

    .text-username {
        position: relative;
        left: 5px;
        top: 0;
        font-size: 14px;
    }

    .custom-dropdown {
        top: 9px !important;
        z-index: 99999;
        #menu-list-grow {
            li{
                padding: 0;
                a {
                    padding: 4px 15px;
                    font-size: 14px;
                    display: block;
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 959px) {
    .header-link-container {
        padding-left: 0;
        width: 280px;
    }

    .header-links {
        display: flex;
        flex-direction: column;
        width: 100%;
        left: 0;

        > *:hover {
            cursor: pointer;
            @include themify-no-context {
                background-color: rgba(0, 0, 0, 0.04);;
            }
        }

        > li {
            padding : 0;
            > * {
                span, a {
                    display: block;
                    width: 100%;
                }

                a {
                    padding: 16px 32px;
                }
            }
        }

        .user-avtar {
            padding: 8px 32px;
        }
    }
}
