@import "../../../assets/css/theme.scss";

.investment-modal {
  p {
    margin: 0;
  }

  h2.modal-title {
    font-size: 24px;
    font-weight: 400;
    margin: 0;
    padding: 0 15px 10px;
  }

  .title-box {
    display: flex;
    align-items: stretch;
    @include themify-no-context {
      border-bottom: 2px solid $table-border;
    }

    .property-img {
      width: 110px;
      height: 80px;
    }

    .assets-box {
      flex-grow: 12;

      .txt-asset-title {
        font-size: 18px;
        margin-top: 25px;
      }

      .txt-asset-symbol {
        @include themify-no-context {
          color: $dashboard-asset;
        }
        font-size: 14px;
      }
    }
  }

  .title-box > div {
    flex-grow: 1;
    padding: 10px 15px;
  }

  .content-box {
    @include themify-no-context {
      border-top: 1px solid $table-border;
    }

    .card-box {
      margin-top: 10px;

      p {
        font-size: 16px;
        margin: 0 0 10px;
      }

      .Mui-error {
        font-size: 12px;
        margin: 0 0 5px;
      }

      table {
        margin: 10px 0;
        font-size: 16px;
        font-weight: 300;
      }
    }
    .block-1 {
      .payment-method-box {
        margin: 20px 0 0;

        .title-text {
          font-size: 16px;
          margin: 0 0 20px;
        }
        .MuiGrid-spacing-xs-4 {
          margin: 0 -16px;
        }
        .MuiGrid-spacing-xs-4 > .MuiGrid-item {
          padding: 0 16px;
        }
        .payment-type {
          padding: 20px 0;
          text-align: center;
          border-radius: 15px;

          svg {
            height: 38px;
            width: 38px;
          }

          .coming-soon-title {
            @include themify-no-context {
              background: $dashboard-button-buy;
              color: $background;
            }
            line-height: 30px;
            font-size: 15px;
            margin: 0 0 10px;
          }
        }

        .payment-type.active {
          @include themify-no-context {
            border: 1px solid $dashboard-button-buy;
          }
        }
        .payment-type.disabled {
          opacity: 0.5;
        }
      }
    }

    .block-2 {
      .table {
        .icon {
          @include themify-no-context {
            background: $background-gray;
          }
          width: 35px;
          height: 35px;
          text-align: center;
          border-radius: 50%;
          line-height: 45px;
        }
        .icon.success {
          @include themify-no-context {
            color: $text-secondary;
          }
        }
        .icon.pending {
          @include themify-no-context {
            color: $dashboard-button-buy;
          }
        }
        td:nth-child(2) {
          line-height: 40px;
          padding-left: 10px;
        }
      }
    }

    .block-3 {
      .table.details-table {
        font-size: 15px;
        td {
          padding: 5px 10px;
        }
        td:nth-child(2) {
          text-align: right;
          font-weight: 500;
        }
      }

      .table.downloads-table {
        font-size: 14px;
        td:nth-child(1) {
          @include themify-no-context {
            color: $dashboard-button-buy;
          }
          padding-left: 25px;
        }
        td:nth-child(2) {
          line-height: 25px;
        }

        .doc-name {
          @include themify-no-context {
            color: $button-text;
          }
        }
      }

      .acceptance-box {
        margin-top: 20px;
        @include themify-no-context {
          .MuiSvgIcon-root {
            fill: $dashboard-date;
          }
        }
        p {
          margin: 0;
          font-size: 15px;
          font-weight: 300;
        }
        .MuiFormControlLabel-root {
          margin-top: 10px;
        }
      }
    }

    .block-4 {
      .txt-style-1 {
        font-size: 22px;
        margin: 0 0 15px;
      }
      .txt-style-2 {
        font-size: 15px;
      }
    }

    .btn-action {
      @include themify-no-context {
        background: $dashboard-button-buy;
        color: $background;
      }
      font-size: 16px;
      margin: 20px 0 10px;
    }
  }
}

.order-modal {
  .order-book-form {
    min-width: 500px;

    p {
      margin: 0;
    }

    h2.modal-title {
      font-size: 24px;
      font-weight: 400;
      margin: 0;
      padding: 0 15px 10px;
    }

    .title-box {
      display: flex;
      align-items: stretch;
      @include themify-no-context {
        border-bottom: 2px solid $table-border;
      }

      .property-img {
        width: 110px;
        height: 80px;
      }

      .assets-box {
        flex-grow: 12;

        .txt-asset-title {
          font-size: 18px;
          margin-top: 25px;
        }

        .txt-asset-symbol {
          @include themify-no-context {
            color: $dashboard-date;
          }
          font-size: 14px;
        }
      }
    }

    .title-box > div {
      flex-grow: 1;
      padding: 10px 15px;
    }

    .content-box {
      @include themify-no-context {
        border-top: 1px solid $table-border;
      }

      .card-box {
        margin: 10px;
        margin-bottom: 20px;

        .MuiCardContent-root {
          padding: 0px;

          p {
            font-size: 16px;
            margin: 0 0 10px;
          }

          .Mui-error {
            font-size: 12px;
            margin: 0 0 5px;
          }

          .tabs-header-container {
            .MuiTabs-scrollButtons {
              display: none !important;
            }

            .MuiAppBar-colorDefault {
              @include themify-no-context {
                color: change-color($button-text, $alpha: 0.87);
                background-color: $table-border;
              }
            }
            .tab-header-name {
              font-size: 14px;
              min-width: calc(100% / 2);
              word-wrap: break-word;
              white-space: normal;
              padding: 20px;
              @include themify-no-context {
                background-color: $table-border;
                color: $dashboard-date;
              }
              font-weight: 600;
              border-bottom: none;
              border: none;

              span {
                width: min-content;
              }
            }

            .MuiTabs-indicator {
              display: none;
            }

            .Mui-selected {
              @include themify-no-context {
                color: $background;
                background-color: $dashboard-button-buy;
              }
            }
          }

          .order-book-panel,
          .recent-transaction-panel {
            overflow-x: scroll;

            .MuiBox-root {
              padding: 24px 5px;
              table {
                width: 100%;
                margin: 10px 0px 70px 0px;
                font-size: 14px;
                font-weight: 400;
                overflow: scroll;

                thead {
                  tr {
                    th {
                      padding: 7px;
                      text-align: center;
                      min-width: calc(100% / 6);
                      @include themify-no-context {
                        border-bottom: 1px solid $button-text;
                      }
                      white-space: nowrap;
                    }
                  }
                }

                tbody {
                  tr {
                    td {
                      padding: 7px;
                      text-align: center;
                      min-width: calc(100% / 6);
                      @include themify-no-context {
                        border-bottom: 1px solid $background-gray;
                      }
                      white-space: nowrap;

                      &:last-child {
                        @include themify-no-context {
                          color: $dashboard-button-buy;
                        }
                        font-weight: 500;
                      }
                    }
                  }
                  tr {
                    &:nth-child(odd) {
                      @include themify-no-context {
                        background-color: $react-table-border;
                      }
                    }
                  }
                }
              }
            }
          }

          .recent-transaction-panel {
            .MuiBox-root {
              padding: 24px 5px;
              table {
                tbody {
                  tr {
                    td {
                      &:last-child {
                        @include themify-no-context {
                          color: $table-text !important;
                        }
                        font-weight: 400 !important;
                      }
                    }
                  }
                }
              }
            }
          }

          .place-order-panel {
            .MuiBox-root {
              padding: 24px;

              .btn-container {
                text-align: center;
                padding: 15px 0px;

                .selling-button {
                  @include themify-no-context {
                    background-color: $table-border;
                    color: $dashboard-date;
                  }
                }

                .btn-sell {
                  padding: 2px 0px;
                  border-radius: 0px;
                  box-shadow: none;
                  font-size: 16px;
                  max-width: 115px;
                  text-transform: uppercase;
                }
                .btn-buy {
                  padding: 2px 0px;
                  border-radius: 0px;
                  box-shadow: none;
                  font-size: 16px;
                  max-width: 115px;
                  text-transform: uppercase;
                }

                @include themify-no-context {
                  .btn-buy-active {
                    background-color: $text-secondary;
                    color: $background;
                  }

                  .btn-sell-active {
                    background-color: $progress-info-background;
                    color: $background;
                  }
                }
              }
              .inputbox-container {
                padding: 15px 0px;
                @include themify-no-context {
                  border-bottom: 1px solid $background-gray;
                }

                .input-field {
                  .MuiTextField-root {
                    .MuiInputLabel-outlined {
                      transform: translate(12px, 14px) scale(1);
                    }
                    .MuiOutlinedInput-root {
                      input {
                        padding: 12px 10px !important;
                      }
                    }
                  }
                }

                .input-container-quantity {
                  padding: 10px;
                }
                .input-container-price {
                  padding: 10px;
                }
              }

              .sales-information-container {
                margin: 10px auto;
                max-width: 470px;
                width: 100%;

                .value-block {
                  .sale-price-container {
                    .txt-amount {
                      float: right;
                    }
                  }
                  .sale-fee-container {
                    .txt-amount {
                      float: right;
                    }
                  }
                  .line-breaker {
                    margin: 5px auto;
                    @include themify-no-context {
                      border: 1px solid $button-text;
                    }
                    max-width: 100%;
                  }
                  .sale-total-container {
                    span {
                      font-weight: 600;
                    }
                    .total-amount {
                      float: right;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .action-button-container {
        margin: 10px;

        .btn-action {
          font-size: 16px;
          text-transform: uppercase;
        }

        @include themify-no-context {
          .place-order {
            background: $dashboard-button-buy;
            color: $background;
          }

          .place-sell-order {
            background-color: $progress-info-background;
            color: $background;
          }

          .btn-nxt {
            background-color: $text-secondary;
            color: $background;
          }
        }
      }
    }
  }
}

.investment-wallet-modal {
  p {
    margin: 0;
  }

  .title-box {
    display: flex;
    align-items: stretch;
    @include themify-no-context {
      border-bottom: 2px solid $table-border;
    }

    .btn-secondary {
      margin-top: 35px;
    }

    .property-img {
      width: 110px;
      height: 80px;
    }

    .assets-box {
      flex-grow: 12;

      .txt-asset-title {
        font-size: 18px;
        margin-top: 25px;
      }

      .txt-asset-symbol {
        @include themify-no-context {
          color: $dashboard-date;
        }
        font-size: 14px;
      }
    }
  }

  .title-box > div {
    flex-grow: 1;
    padding: 10px 15px;
  }

  .market-value-box {
    display: flex;
    align-items: stretch;

    > div {
      flex-grow: 1;
      padding: 10px 15px;
    }

    .txt-value {
      font-size: 18px;
      font-weight: 500;
      margin: 5px 0;
    }

    .txt-title {
      font-size: 15px;
      font-weight: 300;
    }
  }

  .charts-box {
    margin: 20px 0;
    height: 150px;
  }

  .market-statistics-box {
    .table {
      font-size: 15px;

      td {
        padding: 5px 10px;
      }

      td:nth-child(1) {
        font-weight: 300;
      }

      td:nth-child(2) {
        font-weight: 500;
      }
    }

    @include themify-no-context {
      .CircularProgressbar-path {
        stroke: $progress-info-background;
      }
      
      .CircularProgressbar-trail {
        stroke: $background;
      }
    }

    .txt-value {
      text-align: center;
      font-size: 26px;
      @include themify-no-context {
        color: $text-color;
      }
    }

    .txt-title {
      text-align: center;
      font-size: 14px;
      @include themify-no-context {
        color: $dashboard-asset;
      }
    }

    .progress-bar-share {
      width: 110px;
      margin: 15px auto;
      @include themify-no-context {
        color: $progress-info-background;
      }

      .txt-progress-bar-value {
        font-size: 32px;
      }

      .txt-progress-bar-title {
        font-size: 14px;
      }
    }
  }

  .action-link {
    margin-top: 20px;
  }
}

.transactions-modal,
.payment-detail-modal,
.deposit-modal,
.token-pay-modal,
.transfer-property-modal {
  .MuiDialog-paperWidthXs {
    max-width: 400px;
  }

  .block-header {
    height: 120px;
    width: 100%;
    @include themify-no-context {
      background: $dashboard-button-buy;
    }
  }

  p {
    margin: 0;
  }

  .MuiDialogContent-root {
    padding: 0 !important;
  }

  .payment-icon {
    @include themify-no-context {
      background: $table-border;
      border: 3px solid $background;
      color: $dashboard-button-buy;
    }
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    margin: 0 auto;
    position: relative;
    top: -20px;

    svg {
      width: 16px;
    }
  }

  .block-content {
    text-align: center;

    .txt-title {
      font-size: 20px;
      margin-bottom: 12px;
    }

    .txt-type {
      font-size: 18px;
    }

    .txt-status {
      font-size: 14px;
      @include themify-no-context {
        background: $table-border;
        color: $text-profit;
      }
      width: 90px;
      margin: 10px auto;
      padding: 5px 0;
    }
  }

  .list-item {
    padding: 10px 20px;
    @include themify-no-context {
      border-top: 1px solid $table-border;
      border-bottom: 1px solid $table-border;
    }

    .left-block {
      display: inline-block;
    }

    .right-block {
      text-align: right;
      display: inline-block;
      float: right;
    }

    .txt-amount,
    .txt-value {
      font-size: 18px;
    }

    .txt-date,
    .txt-title {
      font-size: 15px;
      @include themify-no-context {
        color: $dashboard-date;
      }
    }
  }

  .btn-print {
    @include themify-no-context {
      color: $dashboard-deposit;
    }
    margin: 10px 0px;
    margin-left: 20px;
  }

  .qr-canvas {
    display: flex;
    justify-content: center;
  }
}

.deposit-modal.wider-modal,
.token-pay-modal.wider-modal,
.transfer-property-modal.wider-modal {
  .block-content {
    padding: 0 2rem;
  }

  .MuiDialog-paperWidthXs {
    max-width: 600px;
  }
}

.token-pay-modal.wider-modal {
  .MuiDialog-paperWidthXs {
    max-width: 800px;
  }
}

.deposit-modal,
.token-pay-modal,
.transfer-property-modal {
  .block-header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    letter-spacing: 1px;
    @include themify-no-context {
      background: $dashboard-payment-account-card-background;
      color: #fff;
    }
  }

  .block-content {
    .txt-status {
      font-size: 1.1rem;
      width: 120px;
      margin: auto;
      margin-bottom: 20px;
      padding: 7px 10px;
      @include themify-no-context {
        background: $table-border;
        color: $dashboard-button-buy;
      }
    }
  }

  .text {
    text-align: justify;
    text-justify: inter-word;
    margin-bottom: 1rem;
  }

  .list-item {
    .txt-value {
      font-size: 1rem;
    }

    .txt-amount {
      @include themify-no-context {
        color: $text-profit;
      }
    }
  }

  .btn-copy {
    width: 100px;
    @include themify-no-context {
      color: $dashboard-deposit;
    }
  }
}

.transfer-property-modal {
  .block-header {
    @include themify-no-context {
      background: rgb(146, 158, 158);
    }
  }

  .block-content {
    .txt-type {
      margin-bottom: 7px;
    }
  }
}

.token-pay-modal {
  .block-header {
    @include themify-no-context {
      background: linear-gradient(180deg, rgba(42,142,227,1) 0%, rgba(0,212,255,1) 100%);
      color: #fff;
    }
  }

  .block-content {
    .txt-cost {
      @include themify-no-context {
        color: $dashboard-button-buy;
      }
    }
  }
}

// delete this part maybe ?
.escrow-modal {
  .block-header {
    @include themify-no-context {
      background: $dashboard-button-buy;
    }
  }

  .block-content {
    .txt-status {
      font-size: 1.1rem;
      width: 120px;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 7px 10px;
      text-align: center;
      @include themify-no-context {
        background: $table-border;
        color: $dashboard-button-buy;
      }
    }
  }

  .text {
    text-align: justify;
    text-justify: inter-word;
    margin-bottom: 1rem;
  }

  .text-title {
    @include themify-no-context {
      color: $dashboard-asset;
    }
  }

  .card-partner {
    max-width: 600px;
    border-radius: 8px;
    margin-top: 10px;
  }

  .list-root {
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    position: relative;
    overflow: auto;
    max-height: 500px;
  }

  .list-section {
    background-color: inherit;
  }

  .list-ul {
    background-color: inherit;
    padding: 0;
  }
}

.trade-modal {
  .trade-modal-content {
    max-height: 750px;
  }

  .qr-canvas {
    display: flex;
    justify-content: center;
  }

  p {
    margin: 0;
  }

  h2.modal-title {
    font-size: 24px;
    font-weight: 400;
    margin: 0;
    padding: 0 15px 10px;
  }

  .title-box {
    display: flex;
    align-items: stretch;
    @include themify-no-context {
      border-bottom: 2px solid $table-border;
    }

    .property-img {
      width: 110px;
      height: 80px;
    }

    .assets-box {
      flex-grow: 12;

      .txt-asset-title {
        font-size: 18px;
        margin-top: 25px;
      }

      .txt-asset-symbol {
        @include themify-no-context {
          color: $dashboard-date;
        }
        font-size: 14px;
      }
    }
  }

  .title-box > div {
    flex-grow: 1;
    padding: 10px 15px;
  }

  .content-box {
    @include themify-no-context {
      border-top: 1px solid $table-border;
    }

    .card-box {
      margin-top: 10px;
    }

    .block-1 {
      .payment-method-box {
        margin: 20px 0 0;

        .title-text {
          font-size: 16px;
          margin: 0 0 20px;
        }
        .MuiGrid-spacing-xs-4 {
          margin: 0 -16px;
        }
        .MuiGrid-spacing-xs-4 > .MuiGrid-item {
          padding: 0 16px;
        }
        .payment-type {
          padding: 20px 0;
          text-align: center;
          border-radius: 15px;

          svg {
            height: 38px;
            width: 38px;
          }

          .coming-soon-title {
            @include themify-no-context {
              background: $dashboard-button-buy;
              color: $background;
            }
            line-height: 30px;
            font-size: 15px;
            margin: 0 0 10px;
          }
        }

        .payment-type.active {
          @include themify-no-context {
            border: 1px solid $dashboard-button-buy;
          }
        }
        .payment-type:not(.disabled) {
          cursor: pointer;
          &:hover {
            background-color: rgb(248, 248, 248);
          }
        }
        .payment-type.disabled {
          opacity: 0.5;
          cursor: default;
        }
      }
    }

    .utrust-logo{
      width: 80%;
      margin-bottom: 20px;
    }

    .utrust-logo-step-wizard{
      width: 250px;
      padding: 0 40px;
      margin: 15px auto;
      display: block;
    }

    .block-2 {
      .table.details-table {
        font-size: 15px;
        margin-top: 30px;
        margin-bottom: 0px;

        tr:last-child {
          td {
            font-weight: 600;
          }
        }

        td {
          padding: 5px 10px;
        }
        td:nth-child(2) {
          text-align: right;
          font-weight: 500;
          white-space: nowrap;
        }
      }

      .table.downloads-table {
        font-size: 14px;
        margin: 25px 0px 25px 50px;

        tbody {
          tr {
            td:nth-child(1) {
              @include themify-no-context {
                color: $dashboard-button-buy;
              }
            }
            td:nth-child(2) {
              line-height: 25px;
            }
          }
        }

        .doc-name {
          @include themify-no-context {
            color: $button-text;
          }
        }
      }

      .acceptance-box {
        position: relative;
        margin-top: 20px;
        .MuiSvgIcon-root {
          fill: $dashboard-date;
        }
        p {
          margin: 0;
          font-size: 15px;
          font-weight: 300;
        }
        .MuiFormControlLabel-root {
          margin-top: 10px;
        }
      }
    }

    .block-3 {
      .txt-style-1 {
        font-size: 22px;
        margin: 0 0 15px;
      }
      .txt-style-2 {
        font-size: 15px;
      }
    }

    .btn-action {
      @include themify-no-context {
        background: $dashboard-button-buy;
        color: $background;
      }
      font-size: 16px;
      margin: 20px 0 10px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .order-modal {
    .order-book-form {
      min-width: auto;

      .content-box {
        .card-box {
          .MuiCardContent-root {
            .MuiTabs-root {
              .MuiTabs-scrollable {
                .MuiTabs-flexContainer {
                  display: grid !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .investment-modal {
    h2.modal-title {
      padding: 0 0 10px;
    }
    .title-box {
      flex-direction: column;

      > div {
        padding: 0;
      }

      .assets-box {
        padding: 10px 0;
        .txt-asset-title {
          margin-top: 0;
        }
      }

      .btn-secondary {
        margin: 10px 0;
      }
    }
  }

  .order-modal {
    .order-book-form {
      min-width: auto;

      h2.modal-title {
        padding: 0 0 10px;
      }
      .title-box {
        flex-direction: column;

        > div {
          padding: 0;
        }

        .assets-box {
          padding: 10px 0;
          .txt-asset-title {
            margin-top: 0;
          }
        }

        .btn-secondary {
          margin: 10px 0;
        }
      }
    }
  }

  .place-order-panel {
    .MuiBox-root {
      .sales-information-container {
        .value-block {
          .sale-price-container {
            .text-title {
              white-space: nowrap;
            }
          }
          .sale-fee-container {
            margin-bottom: 27px;
            .text-title {
              white-space: nowrap;
            }
          }
          .sale-total-container {
            span {
              font-weight: 600;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  .investment-wallet-modal {
    .title-box {
      flex-direction: column;

      > div {
        padding: 0;
      }

      .assets-box {
        .txt-asset-title {
          margin-top: 0;
        }
      }

      .btn-secondary {
        margin: 10px 0;
      }
    }

    .charts-box {
      height: auto;
    }

    .market-value-box {
      flex-direction: column;

      > div {
        padding: 0;
      }
    }

    .action-link {
      p {
        text-align: center;
      }
    }
  }

  .trade-modal {
    h2.modal-title {
      padding: 0 0 10px;
    }
    .title-box {
      flex-direction: column;

      > div {
        padding: 0;
      }

      .assets-box {
        padding: 10px 0;
        .txt-asset-title {
          margin-top: 0;
        }
      }

      .btn-secondary {
        margin: 10px 0;
      }
    }

    .content-box {
      .block-2 {
        .table.details-table {
          font-size: 15px;
          margin-top: 9px;
          margin-bottom: 0px;

          tbody {
            td {
              padding: 5px 10px;
            }
            td:nth-child(2) {
              text-align: right;
              font-weight: 500;
            }
          }
        }

        .table.downloads-table {
          font-size: 14px;
          margin: 25px 0px 25px 20px;
        }
      }
    }
  }
}
